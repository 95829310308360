import { useEffect, useState } from 'react';
import { 
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonIcon
} from '@ionic/react';
import { arrowBack as ArrowBackIcon } from 'ionicons/icons'; 

import Question from '../components/Question';
import PromptsList from '../components/PromptsList';
import { tempPrompts } from '../../prompts';

const QuestionsList: React.FC = () => {

  const [prompts, setPrompts] = useState(tempPrompts);  
  const [currentPromptTitle, setCurrentPromptTitle] = useState('Assessment')
  const [currentPrompt, setCurrentPrompt] = useState({})
  const [customerInfo, updateCustomerInfo] = useState({})
  const [currentQuestion, setCurrentQuestion] = useState('')
  const [allowBack, setBackButton] = useState(false);
  
  const updatePrompts = (updatedPrompts) => {
    setPrompts(updatedPrompts)
  }

  const updatePrompt = (promptTitle, prompt) => {
    setCurrentPromptTitle(promptTitle);
    setCurrentPrompt(prompt);
    setBackButton(true);
  }

  const resetPrompts = () => {
    setPrompts(tempPrompts)
  }

  const changeQuestion = (currentIndex, direction) => {
    
  }

  const setCustomerInfo = (firstName, lastName, phoneNumber) => {
    let newCustomer = {
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber
    }
    updateCustomerInfo(newCustomer)
  }

  let content = currentPromptTitle !== 'Assessment' ? <Question currentPrompt={currentPrompt} customerInfo={customerInfo}/> : <PromptsList updatePrompt={updatePrompt} prompts={prompts} setCustomerInfo={setCustomerInfo}/>;
  // let backButton = allowBack ?
  // <IonButtons slot="start">  
  //     <IonButton onClick={() => {         
  //       setCurrentPromptTitle('Questions')
  //       setBackButton(false);
  //       resetPrompts()
  //     }}>
  //       <IonIcon slot="icon-only" icon={ArrowBackIcon} />
  //     </IonButton>
  //   </IonButtons>  
  // :
  // null;  
  // console.log(prompts);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{currentPromptTitle}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {content}      
      </IonContent>      
    </IonPage>
  )
} 

export default QuestionsList;