
export const services = [
    {
        id: '1',
        service: 'Interactions',
        logo: 'https://patientsync-icons.s3.amazonaws.com/D3_PatientSync_Icon_InteractionsLog+(002).png',
        host: 'http://localhost:3000',        
    },
    {
        id: "2",
        service: "Assessments",
        logo: 'https://patientsync-icons.s3.amazonaws.com/checkList.jpg'
    }

]