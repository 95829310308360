export const tempPrompts = [
  {
    id: '1',
    symptom: 'Chest Pain',
    mainQuestions : [
        'Have you had any pain or pressure in your chest, neck, or arm?'
    ],
    mainResponses : [],     
    followUp : [
      'Onset', 'Location', 'Duration', 'Characteristics', 'Aggravating/Alleviating Factors', 'Radiation', 'Treatment', 'Effects', 'Severity', 'Associated Symptoms'
    ],
    followUpQuestions : [
      'When did the pain start?', 'Where is the pain?', 'When it occurs, how long does the pain last? Is it constant or intermittent?', 'Describe what the pain feels like (e.g., sharp, dull, heavy, etc.).', 'What brings on the pain? What relieves the pain?', 'Does the pain radiate anywhere?', 'What have you used to treat the pain?', 'What effect has the pain had on you?', 'How severe is the pain from 0-10 when it occurs?', 'Have you experienced any nausea or sweating with the chest pain?'
    ],
    followUpResponses : [] 
  },
  {
    id: '2',
    symptom: "Shortness of Breath",
    mainQuestions : [
      'Do you ever feel short of breath with activity?', 'Do you ever feel short of breath while sleeping?', 'Do you feel short of breath when lying flat?'  
    ],
    mainResponses : [],
    followUpQuestions : [
      'What level of activity elicits shortness of breath?', 'How long does it take you to recover?', 'Have you ever woken up from sleeping feeling suddenly short of breath(paroxysmal nocturnal dyspnea)?', 'How many pillows do you need to sleep, or do you sleep in a chair (orthopnea)?', 'Has this recently changed?'
    ],
    followUpResponses : []
  },
  {
    id: '3',
    symptom: 'Edema',
    mainQuestions : [
      'Have you noticed swelling of your feet or ankles?', 'Have you noticed your rings, shoes, or clothing feel tight at the end of the day?', 'Have you noticed any unexplained, sudden weight gain?', 'Have you noticed any new abdominal fullness?'  
    ],
    mainResponses : [],
    followUpQuestions : [
        'Has this feeling of swelling or restriction gotten worse?', 'Is there anything that makes the swelling better (e.g., sitting with your feet elevated)?', 'How much weight have you gained? Over what time period have you gained this weight?'
    ],
    followUpResponses : []
  }
]