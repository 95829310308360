import { useState, useEffect } from "react";
import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCheckbox, IonIcon, IonInput, IonItem, IonLabel } from "@ionic/react";
import { arrowForward as ArrowForwardIcon } from 'ionicons/icons';
interface question {
    currentPrompt: any
    customerInfo: any
}

const Question: React.FC<question> = ({currentPrompt, customerInfo}) => {

  const [showSubmit, setShowSubmit] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState('');
  const [currentQuestionType, setCurrentQuestionType] = useState('');
  const [currentResponse, setCurrentResponse] = useState('');
  const [mainQuestionIndex, setMainQuestionIndex] = useState(0);
  const [followUpQuestionIndex, setFollowUpQuestionIndex] = useState(0);
  const [questionSource, setQuestionSource] = useState('Main');
  const [mainResponses, setMainResponses] = useState([]);
  const [followUpResponses, setFollowUpResponses] = useState([]);
  
  useEffect(() => {
    setCurrentQuestion(currentPrompt.mainQuestions[0]);
    setCurrentQuestionType('bool');    
  }, [])

  let nextQuestion = (response) => {      
    let mainArr = [...mainResponses];
    let followArr = [...followUpResponses];    
    console.log('res in function', response)
    if(questionSource === 'Main') {
        mainArr.push(response);      
        console.log('arr in function', mainArr)
        setMainResponses(mainArr)  
      if(mainArr.length !== currentPrompt.mainQuestions.length) {
        let newIndex = mainQuestionIndex + 1;  
        setMainQuestionIndex(newIndex);
        setCurrentQuestion(currentPrompt.mainQuestions[newIndex])
      } else {        
        setQuestionSource('Followup')
        setCurrentQuestion(currentPrompt.followUpQuestions[0])
        setCurrentQuestionType('Input')
      }  
    } else {
      followArr.push(response);
      let newIndex = followUpQuestionIndex + 1;
      setFollowUpQuestionIndex(newIndex);
      setCurrentQuestion(currentPrompt.followUpQuestions[newIndex])
      setFollowUpResponses(followArr)
    }
    setCurrentResponse('')
    if((mainArr.length === currentPrompt.mainQuestions.length) && (followArr.length === currentPrompt.followUpQuestions.length)) {
      setShowSubmit(true);  
    }     
  }

  let questionResponse =
  currentQuestionType === 'bool'
  ?
  <>
  <IonItem button detail onClick={(e) => {      
      nextQuestion('Yes')
      }}>
    <IonLabel>Yes</IonLabel>  
  </IonItem>
  <IonItem button detail onClick={(e) => {      
      nextQuestion('No')
      }}>
    <IonLabel>No</IonLabel>  
  </IonItem>
  </>
  :
  <IonItem>
    <IonInput value={currentResponse} onIonChange={(e) => {setCurrentResponse(e.detail.value)}}></IonInput>
  </IonItem>

  let nextButton = 
  currentQuestionType === 'Input' && currentResponse.length
  ?
  <IonButtons>
    <IonButton slot='end' onClick={e => nextQuestion(currentResponse)}>
      <IonIcon slot="icon-only" icon={ArrowForwardIcon} />
    </IonButton>
  </IonButtons>
  :
  null;

  console.log(currentPrompt);
  console.log(mainResponses);
  console.log(followUpResponses);
  
  if(showSubmit) {
    return (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle>Thank-You {customerInfo.firstName}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <p>If this is a medical emergency, please call 911.</p>                
                <p>One of our patient care team members will call you shortly at {customerInfo.phoneNumber}</p>
            </IonCardContent>            
        </IonCard>
    )  

  } else {
      return (
        <IonCard>
          <IonCardHeader>
            <IonCardSubtitle>{currentQuestion}</IonCardSubtitle> 
          </IonCardHeader>  
          <IonCardContent>
            {questionResponse} 
            {nextButton}
          </IonCardContent>
        </IonCard>
      )  
  }


 
}
 
 export default Question;