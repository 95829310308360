import { 
  IonCard, 
  IonCardContent, 
  IonCardHeader, 
  IonCardTitle, 
  IonSelect, 
  IonSelectOption, 
  IonLabel, 
  IonItem, 
  IonCardSubtitle, 
  IonCheckbox
} from '@ionic/react';
import dayjs from 'dayjs';


import React, { useEffect, useState } from 'react';
import InteractionGrid from './InteractionGrid';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

interface InteractionProps {
  interaction: {
    interaction_id: string,
    escalated: boolean,
    interaction_status: string,
    body: {
      primary_provider: string,
      details: string
      caller: string,
      dob: string,
      callback: string,
      html: string
    },
    incoming_id: string,
    outgoing_dateTime: string,
    modifications: {modify_dateTime: string, modify_details: string, modify_user: string}[],
    checked: boolean,
  },
  changeStatus: Function,
  timeZone: string
  checkboxCsvList: Function
}
//

const Interaction: React.FC<InteractionProps> = React.memo(({ interaction, changeStatus, timeZone, checkboxCsvList }) => {   
  const [date, setDate] = useState('');
  const [time, setTime] = useState('')    

  useEffect(() => {
    let dateTime = interaction.outgoing_dateTime
    let tempDate = dayjs(dateTime).tz(timeZone).format('MMM/DD/YYYY')
    let tempTime = dayjs(dateTime).tz(timeZone).format('h:mm A');
    setDate(tempDate)
    setTime(tempTime) 
  }, [timeZone])

  //add onChange for selection option
  let testColor = "primary";

  if(interaction.interaction_status === 'Completed') {
    testColor = 'success'
  } else if(interaction.interaction_status === 'Pending Review') {
    testColor = 'primary'
  } else if(interaction.interaction_status === 'In Process') {
    testColor = 'warning'
  }

  let escalatedString;
  if(interaction.escalated.toString() === 'true'){
    escalatedString = "Yes"
  } else {
    escalatedString = "No"
  }

  return (
    <div>
      <IonCard >
        <IonCardHeader>  
        <IonCheckbox checked={interaction.checked} onIonChange={e => {                   
            checkboxCsvList(interaction, date, time, timeZone)          
          }}>
        </IonCheckbox>    
          <IonCardTitle>{date}</IonCardTitle>
          <IonCardSubtitle>{time}</IonCardSubtitle>
          <IonCardSubtitle hidden color={testColor}>{interaction.interaction_status}</IonCardSubtitle>
          <IonCardTitle hidden>
            <IonItem>
              <IonLabel>
                Change Status
              </IonLabel>
              <IonSelect onIonChange={event => changeStatus(interaction.interaction_id, event.detail.value)}>
                <IonSelectOption value="Pending Review">Pending Review</IonSelectOption>
                <IonSelectOption value="In Process">In Process</IonSelectOption>
                <IonSelectOption value="Completed">Completed</IonSelectOption>
              </IonSelect>
            </IonItem>
          </IonCardTitle>
          <br />
          <IonCardSubtitle>Escalated To On Call : {escalatedString}</IonCardSubtitle>          
        </IonCardHeader>
        <IonCardContent>
          <InteractionGrid interaction={interaction} timeZone={timeZone}/>
        </IonCardContent>
      </IonCard>
    </div>
  );
});

export default Interaction

{/* <IonCard >

<IonCardContent>
  <InteractionGrid interaction={interaction} />
</IonCardContent>
</IonCard> */}