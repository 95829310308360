import { useEffect, useState } from 'react';
import { 
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonItemDivider,
  IonDatetime
 } from '@ionic/react';

import Question from '../components/Question';


interface promptsList { 
  updatePrompt: Function
  prompts: any  
  setCustomerInfo: Function
}

const PromptsList: React.FC<promptsList> = ({updatePrompt, prompts, setCustomerInfo}) => {  
  //need a clickable list to get into next section
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [dob, setDOB] = useState('');

  return (
    <>
      <IonItem>
        <IonLabel position='stacked'>First Name</IonLabel>  
        <IonInput value={firstName} required={true} type='text' onIonChange={(e) => {setFirstName(e.detail.value)}}></IonInput> 
      </IonItem>
      <IonItem>
        <IonLabel position='stacked'>Last Name</IonLabel>    
        <IonInput value={lastName} required={true} type='text' onIonChange={(e) => {setLastName(e.detail.value)}}></IonInput> 
      </IonItem>
      <IonItem>
        <IonLabel position='stacked'>Phone Number</IonLabel>
        <IonInput value={phoneNumber} required={true} type='tel' onIonChange={(e) => {setPhoneNumber(e.detail.value)}}></IonInput>  
      </IonItem>
      <IonItem>
        <IonLabel position='stacked'>Date of Birth</IonLabel>
        <IonDatetime value={dob}  onIonChange={(e) => {setDOB(e.detail.value)}}></IonDatetime>  
      </IonItem>
      <IonItem color="medium">Symptoms</IonItem>      
      <IonList>
        {prompts.map((prompt) => {
          console.log(prompt)  
          return ( 
            <IonItem button detail key={prompt.id} onClick={(e) => {
                updatePrompt(prompt.symptom, prompt)
                setCustomerInfo(firstName, lastName, phoneNumber)
                }}>          
              <IonLabel>{prompt.symptom}</IonLabel>            
            </IonItem>  
           )
         })}
      </IonList>
    </>
  )
} 

export default PromptsList;