import React from "react";

import InteractionsApp from '../Apps/InteractionsApp/src/InteractionsApp';
import QuestionsApp from '../Apps/Questions/src/QuestionsApp';

interface MicroInstance {
    name: string,    
    history: History
}

const Micro: React.FC<MicroInstance> = ({name}) => {
 
  let microApp;
  if(name === "Interactions") {
    microApp = <InteractionsApp />
  } 
  if(name === "Assessments") {
    microApp = <QuestionsApp />
  }
  console.log(name)
  return(
    <div>
      {microApp}
    </div>
  )  
  
};

export default Micro